import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
    GET_BUILDER_DATA,
    GET_BUILDER_DATA_SUCCESS,
    GET_BUILDER_DATA_FAILED,
    VIEW_BUILDER_DATA,
    VIEW_BUILDER_DATA_SUCCESS,
    VIEW_BUILDER_DATA_FAILED,
    UPDATE_BUILDER_DATA,
    UPDATE_BUILDER_DATA_SUCCESS,
    UPDATE_BUILDER_DATA_FAILED,
    CREATE_BUILDER_DATA,
    CREATE_BUILDER_DATA_SUCCESS,
    CREATE_BUILDER_DATA_FAILED
} from "constants/ActionTypes";

import { searchBuilderApi, updateBuilderApi, viewBuilderApi, createBuilderApi } from "../api/Builder";

function* fetchListDataBuilder({payload}) {
    if(payload != null){
        try {
            const response = yield call(searchBuilderApi, payload);
            if (response.data.abstractResponse.responseStatus === 'BUILDERTEMPLATE007' && response.data.abstractResponse.responseMessage !== "Builder Template Empty") {
                yield put({type: GET_BUILDER_DATA_SUCCESS, payload: response.data});
            } else {
                yield put({type: GET_BUILDER_DATA_FAILED, payload: response.data.abstractResponse.responseMessage});
            }
        } catch (error) {
            console.log("API search builder: ", error)
            if(error.response !== undefined) {
                yield put({
                    type: GET_BUILDER_DATA_FAILED,
                    payload: error.response.data.responseMessage
                });
            }else{
                yield put({
                    type: GET_BUILDER_DATA_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

function* viewListDataBuilder({payload}) {
    if(payload != null){
        try {
            const response = yield call(viewBuilderApi, payload);
            // console.log(response)
            if (response.data.abstractResponse.responseStatus === 'BUILDERTEMPLATE008' && response.data.abstractResponse.responseMessage === "View Builder Template Success") {
                yield put({type: VIEW_BUILDER_DATA_SUCCESS, payload: response.data});
            } else {
                yield put({type: VIEW_BUILDER_DATA_FAILED, payload: response.data.abstractResponse.responseMessage});
            }
        } catch (error) {
            console.log("API view builder: ", error)
            if(error.response !== undefined) {
                yield put({
                    type: GET_BUILDER_DATA_FAILED,
                    payload: error.response.data.responseMessage
                });
            }else{
                yield put({
                    type: GET_BUILDER_DATA_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

function* postUpdateBuilder({payload}) {
    if(payload != null){
        try {
            let response = yield call(updateBuilderApi, payload);
            if (response.data.responseMessage === 'Update Builder Template Success') {
                yield put({type: UPDATE_BUILDER_DATA_SUCCESS, payload: response.data.responseMessage});
            } else {
                yield put({type: UPDATE_BUILDER_DATA_FAILED, payload: response.data.responseMessage});
            }
        } catch (error) {
            if(error.response !== undefined) {
                yield put({
                    type: UPDATE_BUILDER_DATA_FAILED,
                    payload: error.response.data.responseMessage
                });
            }else{
                yield put({
                    type: UPDATE_BUILDER_DATA_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

function* postCreateBuilder({payload}) {
    if(payload != null){
        try {
            let response = yield call(createBuilderApi, payload);
            if (response.data.responseMessage === 'Create Builder Template Success') {
                yield put({type: CREATE_BUILDER_DATA_SUCCESS, payload: response.data.responseMessage});
            } else {
                yield put({type: CREATE_BUILDER_DATA_FAILED, payload: response.data.responseMessage});
            }
        } catch (error) {
            if(error.response !== undefined) {
                yield put({
                    type: CREATE_BUILDER_DATA_FAILED,
                    payload: error.response.data.responseMessage
                });
            }else{
                yield put({
                    type: CREATE_BUILDER_DATA_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

export function* getBuilderData() {
    yield takeEvery(GET_BUILDER_DATA, fetchListDataBuilder);
}

export function* viewBuilderData() {
    yield takeEvery(VIEW_BUILDER_DATA, viewListDataBuilder);
}

export function* postCreateData() {
    yield takeEvery(CREATE_BUILDER_DATA, postCreateBuilder);
}

export function* postBuilderData() {
    yield takeEvery(UPDATE_BUILDER_DATA, postUpdateBuilder);
}

export default function* rootSaga() {
    yield all([
        fork(getBuilderData),
        fork(viewBuilderData),
        fork(postCreateData),
        fork(postBuilderData)
    ])
}