import Axios from "axios";

export const searchApprovalApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	search,
	merchantCode,
	memberName,
	memberUsername,
	mobileNumber,
	startDate,
	endDate,
	page,
	size,
	orderBy,
	orderType,
}) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "receipt/search",
			params: {
				userId: userId,
				merchantId: merchantId,
				memberName: memberName,
				merchantCode: merchantCode,
				memberUsername: memberUsername,
				mobileNumber: search,
				startDate: startDate,
				endDate: endDate,
				page: page,
				size: size,
				nRecords: 10,
				role: 1,
				orderBy: orderBy,
				orderType: orderType,
			},
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const viewApprovalApi = ({ authToken, deviceId, userId, merchantId, page, id }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "receipt/search",
			params: {
				userId: userId,
				merchantId: merchantId,
				// transactionName : search,
				page: 0,
				// size : size,
				nRecords: 10,
				role: 1,
				receiptId: id,
			},
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const updateApprovalApi = ({ authToken, deviceId, userId, merchantId, id, data }) => {
	if (authToken != null) {
		return Axios({
			method: "post",
			url: window.ApiURL + "redeem/product/update",
			data: {
				userId: userId,
				merchantId: merchantId,
				productId: id,
				productCode: data.productCode,
				productName: data.productName,
				description: data.description,
				productType: data.productType,
				productTypeLabel: data.productTypeLabel,
				productImage: data.productImage,
				stock: data.stock,
				basePrice: data.basePrice,
				pointsEarned: data.pointsEarned,
				isAllMerchantExchangePoint: -1,
				detectAccountExchangePoint: 4,
				voucherValue: data.voucherValue,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const inquiryOrPostingApprovalApi = (data) => {
	if (data.authToken != null) {
		return Axios({
			method: "post",
			url: window.ApiURL + "receipt/update",
			data: {
				userId: data.userId,
				merchantId: data.merchantId,
				receiptId: data.id,
				action: data.action,
				totalAmount: data.totalAmount,
				status: data.status,
				description: data.description,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const uploadTransactionApprovalApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	trxUploadId,
	status,
	page,
	pageSize,
	startDate,
	endDate,
	createBy
}) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "promotion/search/posting",
			params: {
				userId: userId,
				merchantId: merchantId,
				page: page,
				nRecords: pageSize,
				trxUploadId: trxUploadId,
				status: status,
				startDate: startDate,
				endDate: endDate,
				createBy: createBy
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}
};
