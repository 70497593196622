import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	BACK_TO_LOGIN,
	GET_ISSUING_REPORT,
	GET_ISSUING_REPORT_FAILED,
	GET_ISSUING_REPORT_SUCCESS,
	DOWNLOAD_SUCCESS,
	GET_MEMBER_BALANCE_REPORT,
	GET_MEMBER_BALANCE_REPORT_FAILED,
	GET_MEMBER_BALANCE_REPORT_SUCCESS,
	GET_POINT_TRANSACTION_REPORT,
	GET_POINT_TRANSACTION_REPORT_FAILED,
	GET_POINT_TRANSACTION_REPORT_SUCCESS,
	GET_REDEEM_REPORT,
	GET_REDEEM_REPORT_FAILED,
	GET_REDEEM_REPORT_SUCCESS,
	GET_REFERRAL_REPORT,
	GET_REFERRAL_REPORT_FAILED,
	GET_REFERRAL_REPORT_SUCCESS,
	GET_VOUCHER_BALANCE_REPORT,
	GET_VOUCHER_BALANCE_REPORT_FAILED,
	GET_VOUCHER_BALANCE_REPORT_SUCCESS,
	GET_POINT_TRANSFER_REPORT,
	GET_POINT_TRANSFER_REPORT_FAILED,
	GET_POINT_TRANSFER_REPORT_SUCCESS,
	GET_PARTNER_REPORT,
	GET_PARTNER_REPORT_SUCCESS,
	GET_PARTNER_REPORT_FAILED,
	GET_REDEEM_PARTNER_REPORT,
	GET_REDEEM_PARTNER_REPORT_SUCCESS,
	GET_REDEEM_PARTNER_REPORT_FAILED,
	GET_GIFTAWAY_VOUCHER_REPORT,
	GET_GIFTAWAY_VOUCHER_REPORT_SUCCESS,
	GET_GIFTAWAY_VOUCHER_REPORT_FAILED,
	UPLOAD_TRANSACTION_APPROVAL_REPORT,
	UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED,
	UPLOAD_TRANSACTION_APPROVAL_REPORT_SUCCESS,
	APPROVE_REJECT_APPROVAL,
	APPROVE_REJECT_APPROVAL_SUCCESS,
	APPROVE_REJECT_APPROVAL_FAILED,
} from "../../constants/ActionTypes";
import {
	issuingReportApi,
	memberBalanceApi,
	pointTransactionApi,
	redeemReportApi,
	referralReportApi,
	voucherBalanceApi,
	pointTransferApi,
	reportPartnerApi,
	redeemPartnerApi,
	giftawayVoucherApi,
	uploadTransactionApprovalApi,
	approveRejectUploadTranasctionApprovalApi,
} from "../api/Report";

function* fetchIssuingReport({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(issuingReportApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_ISSUING_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_ISSUING_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_ISSUING_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_ISSUING_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchRedeemReport({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(redeemReportApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({ type: GET_REDEEM_REPORT_SUCCESS, payload: searchMembersData.data });
				}
			} else {
				yield put({
					type: GET_REDEEM_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_REDEEM_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_REDEEM_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchMemberBalanceReport({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(memberBalanceApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_MEMBER_BALANCE_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_MEMBER_BALANCE_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_MEMBER_BALANCE_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_MEMBER_BALANCE_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchVoucherBalanceReport({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(voucherBalanceApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_VOUCHER_BALANCE_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_VOUCHER_BALANCE_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_VOUCHER_BALANCE_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_VOUCHER_BALANCE_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchReferralReport({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(referralReportApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_REFERRAL_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_REFERRAL_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_REFERRAL_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_REFERRAL_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchPointTransaction({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(pointTransactionApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_POINT_TRANSACTION_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_POINT_TRANSACTION_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_POINT_TRANSACTION_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_POINT_TRANSACTION_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchPointTransfer({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(pointTransferApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_POINT_TRANSFER_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_POINT_TRANSFER_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_POINT_TRANSFER_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_POINT_TRANSFER_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchReportPartner({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(reportPartnerApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_PARTNER_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_PARTNER_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_PARTNER_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_PARTNER_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchRedeemPartnerReport({ payload }) {
	if (payload != null) {
		try {
			const searchMembersData = yield call(redeemPartnerApi, payload);
			if (searchMembersData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: searchMembersData.data });
				} else {
					yield put({
						type: GET_REDEEM_PARTNER_REPORT_SUCCESS,
						payload: searchMembersData.data,
					});
				}
			} else {
				yield put({
					type: GET_REDEEM_PARTNER_REPORT_FAILED,
					payload: searchMembersData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_REDEEM_PARTNER_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_REDEEM_PARTNER_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchGiftawayVoucherReport({ payload }) {
	if (payload != null) {
		try {
			const giftawayData = yield call(giftawayVoucherApi, payload);
			if (giftawayData.data.abstractResponse.responseStatus === "INQ000") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: giftawayData.data });
				} else {
					yield put({
						type: GET_GIFTAWAY_VOUCHER_REPORT_SUCCESS,
						payload: giftawayData.data,
					});
				}
			} else {
				yield put({
					type: GET_GIFTAWAY_VOUCHER_REPORT_FAILED,
					payload: giftawayData.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_GIFTAWAY_VOUCHER_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: GET_GIFTAWAY_VOUCHER_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchUploadTransactionApprovalReport({ payload }) {
	if (payload != null) {
		try {
			const response = yield call(uploadTransactionApprovalApi, payload);
			if (response.data.abstractResponse.responseStatus === "UTS003") {
				if (payload.isDownload) {
					yield put({ type: DOWNLOAD_SUCCESS, payload: response.data });
				} else {
					yield put({
						type: UPLOAD_TRANSACTION_APPROVAL_REPORT_SUCCESS,
						payload: response.data,
					});
				}
			} else {
				console.log('masok sinsi')
				yield put({
					type: UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED,
					payload: response.data.abstractResponses.responseMessage,
				});
			}
		} catch (error) {
			console.log(error, 'masok sinsi')
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: UPLOAD_TRANSACTION_APPROVAL_REPORT_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* postApproveRejectUploadTranasctionApproval({ payload }) {
	if (payload != null) {
		try {
			// yield put({type: RESET_STATUS});

			let response = yield call(approveRejectUploadTranasctionApprovalApi, payload);
			if (response.data.abstractResponse.responseStatus === "UTS003") {
				yield put({
					type: APPROVE_REJECT_APPROVAL_SUCCESS,
					payload: response.data,
				});
			} else if (response.data.abstractResponse.responseStatus === "UTS005") {
				yield put({
					type: APPROVE_REJECT_APPROVAL_SUCCESS,
					payload: response.data.abstractResponse.responseMessage,
				});
			} else {
				yield put({
					type: APPROVE_REJECT_APPROVAL_FAILED,
					payload: response.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse === null) {
					yield put({
						type: APPROVE_REJECT_APPROVAL_FAILED,
						payload: "Sorry, this feature is not accessible at this time",
					});
				} else if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: APPROVE_REJECT_APPROVAL_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: APPROVE_REJECT_APPROVAL_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

export function* getIssuingData() {
	yield takeEvery(GET_ISSUING_REPORT, fetchIssuingReport);
}

export function* getRedeemData() {
	yield takeEvery(GET_REDEEM_REPORT, fetchRedeemReport);
}

export function* getMemberBalanceData() {
	yield takeEvery(GET_MEMBER_BALANCE_REPORT, fetchMemberBalanceReport);
}

export function* getVoucherBalanceData() {
	yield takeEvery(GET_VOUCHER_BALANCE_REPORT, fetchVoucherBalanceReport);
}

export function* getReferralMemberData() {
	yield takeEvery(GET_REFERRAL_REPORT, fetchReferralReport);
}

export function* getPointTransactionData() {
	yield takeEvery(GET_POINT_TRANSACTION_REPORT, fetchPointTransaction);
}

export function* getPointTransferData() {
	yield takeEvery(GET_POINT_TRANSFER_REPORT, fetchPointTransfer);
}

export function* getPartnerData() {
	yield takeEvery(GET_PARTNER_REPORT, fetchReportPartner);
}

export function* getRedeemPartnerData() {
	yield takeEvery(GET_REDEEM_PARTNER_REPORT, fetchRedeemPartnerReport);
}

export function* getGiftawayVoucherData() {
	yield takeEvery(GET_GIFTAWAY_VOUCHER_REPORT, fetchGiftawayVoucherReport);
}

export function* getUploadTransactionApprovalReport() {
	yield takeEvery(UPLOAD_TRANSACTION_APPROVAL_REPORT, fetchUploadTransactionApprovalReport);
}

export function* approveRejectUploadTranasctionApproval() {
	yield takeEvery(APPROVE_REJECT_APPROVAL, postApproveRejectUploadTranasctionApproval);
}

// yield all
export default function* rootSaga() {
	yield all([
		fork(getIssuingData),
		fork(getRedeemData),
		fork(getMemberBalanceData),
		fork(getVoucherBalanceData),
		fork(getReferralMemberData),
		fork(getPointTransactionData),
		fork(getPointTransferData),
		fork(getPartnerData),
		fork(getRedeemPartnerData),
		fork(getGiftawayVoucherData),
		fork(getUploadTransactionApprovalReport),
		fork(approveRejectUploadTranasctionApproval),
	]);
}
