import Axios from "axios";

export const issuingReportApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/issuing",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const redeemReportApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/redeem",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const memberBalanceApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/member-balance",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const voucherBalanceApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/voucher-balance",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				memberUsername: data.memberUsername,
				productName: data.productName,
				startDate: data.startDate,
				endDate: data.endDate,
				status: data.status,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				isOwnProduct: data.isOwnProduct,
				statusList: data.statusList,
				voucherId: data.voucherId,
				voucherCode: data.voucherCode,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const referralReportApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/referral-member",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const pointTransactionApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/point-transaction",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				trxType: data.trxType,
				memberName: data.memberName,
				startDate: data.startDate,
				endDate: data.endDate,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const pointTransferApi = (data) => {
	if (data.authToken != null) {
		// var mulai = 0;
		// var baris = 20;

		// if(page>0){
		//     mulai= pageSize + 1;
		//     baris=0;
		//     for(var i=0;i<=page;){
		//         baris = pageSize + baris;
		//         i++;
		//     }
		//     mulai = baris - 20 + 1;
		// }
		return Axios({
			method: "get",
			url: window.ApiURL + "point-transaction/point/transfer/history",
			params: {
				userId: data.userId,
				merchantId: data.merchantId,
				orderBy: "",
				orderType: 1,
				page: data.page,
				nRecords: data.pageSize,
				role: 1,
				startDate: data.startDate,
				endDate: data.endDate,
				username: data.username,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const reportPartnerApi = (data) => {
	if (data.authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (data.page > 0) {
			mulai = data.pageSize + 1;
			baris = 0;
			for (var i = 0; i <= data.page; ) {
				baris = data.pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/report-partner",
			params: {
				memberId: data.userId,
				merchantId: data.merchantId,
				memberName: data.memberName,
				productName: data.productName,
				offset: mulai,
				pageSize: data.pageSize,
				page: data.page,
				nRecords: data.pageSize,
				merchantIdPartner: data.merchantIdPartner,
				sort: data.sort,
				sortBy: data.sortBy,
			},
			data: {},
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};

export const redeemPartnerApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	memberName,
	productName,
	status,
	page,
	pageSize,
	startDate,
	endDate,
	memberUsername,
	sort,
	sortBy,
}) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/report-redeemed-voucher-partner",
			params: {
				userId: userId,
				merchantId: merchantId,
				memberName: memberName,
				memberUsername: memberUsername,
				productName: productName,
				startDate: startDate,
				endDate: endDate,
				// status : status,
				offset: mulai,
				pageSize: pageSize,
				page: page,
				nRecords: pageSize,
				sort: sort,
				sortBy: sortBy,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const giftawayVoucherApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	memberName,
	page,
	pageSize,
	startDate,
	endDate,
	orderBy,
	orderType,
	sort,
	sortBy,
}) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "report/voucher-gift",
			params: {
				memberId: userId,
				merchantId: merchantId,
				memberName: memberName,
				startDate: startDate,
				endDate: endDate,
				offset: mulai,
				pageSize: pageSize,
				page: page,
				nRecords: pageSize,
				orderBy: orderBy,
				orderType: orderType,
				sort: sort,
				sortBy: sortBy,
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
			},
		});
	}
};

export const uploadTransactionApprovalApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	trxUploadId,
	status,
	page,
	pageSize,
	startDate,
	endDate,
	createBy
}) => {
	if (authToken != null) {
		var mulai = 0;
		var baris = 10;

		if (page > 0) {
			mulai = pageSize + 1;
			baris = 0;
			for (var i = 0; i <= page; ) {
				baris = pageSize + baris;
				i++;
			}
			mulai = baris - 10;
		}
		return Axios({
			method: "get",
			url: window.ApiURL + "promotion/search/posting",
			params: {
				userId: userId,
				merchantId: merchantId,
				page: page,
				nRecords: pageSize,
				trxUploadId: trxUploadId,
				status: status,
				startDate: startDate,
				endDate: endDate,
				createBy: createBy
			},
			data: {},
			headers: {
				authToken: authToken,
				deviceUniqueId: deviceId,
				"Content-Type": "application/x-www-form-urlencoded",
			},
		});
	}
};

export const approveRejectUploadTranasctionApprovalApi = (data) => {
	if (data.authToken != null) {
		return Axios({
			method: "post",
			url: window.ApiURL + "promotion/update/posting",
			data: {
				userId: data.userId,
				merchantId: data.merchantId,
				description: data.description,
				trxUploadId: data.trxUploadId,
				isRejectAll: data.isRejectAll,
				rejectList: data.rejectList,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: data.authToken,
				deviceUniqueId: data.deviceId,
			},
		});
	}
};
